import { render, staticRenderFns } from "./ProfilePictureForAdmin.vue?vue&type=template&id=08a44f12&scoped=true&"
import script from "./ProfilePictureForAdmin.vue?vue&type=script&lang=js&"
export * from "./ProfilePictureForAdmin.vue?vue&type=script&lang=js&"
import style0 from "./ProfilePictureForAdmin.vue?vue&type=style&index=0&id=08a44f12&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08a44f12",
  null
  
)

export default component.exports